import React from "react";

function Valeurs2() {
  return (
    <div className="flex flex-col md:flex-row items-stretch bg-customGreen custom-color py-6 md:h-screen">
      <div className="md:w-1/2 flex flex-col justify-center items-start px-6 md:p-12">
        <p className="font-seasons text-2xl mb-6 mt-0 text-white">
          <span className="text-6xl mr-2">3</span> Une Relation Basée sur un
          Partenariat Solide et Durable
        </p>
        <p className="font-arial text-base text-white">
          Notre relation client repose sur une confiance mutuelle et s'inscrit
          dans la durée. Nous vous accompagnons tout au long de votre vie, en
          ajustant vos stratégies patrimoniales en fonction de vos évolutions
          personnelles et des conditions du marché, assurant ainsi un suivi
          adapté et continu. Notre indépendance nous permet d’évaluer tous les
          produits disponibles sur le marché et de vous proposer ceux qui
          correspondent le mieux à vos besoins. Nous nous engageons à fournir
          des informations claires et exactes sur les produits proposés, en
          garantissant une objectivité totale et une liberté de choix.
        </p>

        <p className="font-seasons text-2xl mb-6 mt-12 text-white">
          <span className="text-6xl mr-2 font-trebuc ">4</span> Un Professionnel
          Indépendant avec Accès à Toutes les Offres du Marché
        </p>
        <p className="font-arial text-base text-white">
          La profession de Conseiller en Gestion de Patrimoine est
          rigoureusement réglementée et régulée par des autorités. Ce cadre
          réglementaire strict assure une protection accrue des clients et
          garantit la qualité des services fournis. Grâce à ces régulations,
          nous pouvons offrir à nos clients des conseils en toute confiance,
          assurant leur protection et leur satisfaction à chaque étape de la
          gestion de leur patrimoine.
        </p>

        <p className="font-seasons text-2xl mb-6 mt-12 text-white">
          <span className="text-6xl mr-2">5</span> Une Profession Encadrée pour
          une Meilleure Protection du Client
        </p>
        <p className="font-arial text-base text-white xl:mb-24 md:mb-96 mb-12">
          La profession de Conseiller en Gestion de Patrimoine est
          rigoureusement réglementée et régulée par des autorités. Ce cadre
          réglementaire strict assure une protection accrue des clients et
          garantit la qualité des services fournis. Grâce à ces régulations,
          nous pouvons offrir à nos clients des conseils en toute confiance,
          assurant leur protection et leur satisfaction à chaque étape de la
          gestion de leur patrimoine.
        </p>
      </div>
    </div>
  );
}

export default Valeurs2;
