import React from "react";

function Valeurs() {
  return (
    <div
      className="flex flex-col md:flex-row items-stretch bg-customGreen custom-color py-6 md:h-screen"
      id="valeurs"
    >
      <div className="md:w-1/2 flex flex-col justify-center items-start px-6 md:p-12">
        <h1 className="font-seasons text-5xl mb-24 text-white">
          Pourquoi nous choisir ?
        </h1>
        <p className="font-seasons text-2xl mb-6 text-white">
          <span className="text-6xl  mr-2 ">1</span> Une Approche Patrimoniale
          Experte et Pluridisciplinaire
        </p>
        <p className="font-arial text-base text-white">
          Dans un contexte où les mesures fiscales et juridiques évoluent
          constamment nous sommes un atout essentiel pour vous accompagner dans
          la construction de vos projets de vie et la réalisation de vos
          objectifs. Expert en gestion patrimoniale, nous vous conseillons sur
          tous les aspects du patrimoine : immobilier, fiscalité, droit,
          retraite, assurance vie, et placements financiers. Nous garantissons
          la confidentialité de toutes les informations patrimoniales.
        </p>
        <p className="font-seasons text-2xl mb-6 mt-12 text-white">
          <span className="text-6xl  mr-2">2</span> Un Diagnostic et des
          Services Personnalisés
        </p>

        <p className="font-arial text-base text-white">
          En tant que spécialiste de la gestion de patrimoine, nous analysons
          tous les éléments de votre patrimoine pour établir un diagnostic
          complet et définir une stratégie d'optimisation patrimoniale sur
          mesure. Grâce à des outils digitaux avancés, nous obtenons une vision
          globale de votre situation et identifions les placements les mieux
          adaptés à vos objectifs.
        </p>
      </div>
      <div className="md:w-1/2 flex items-center justify-center">
        {/* Contenu supplémentaire peut être ajouté ici si nécessaire */}
      </div>
    </div>
  );
}

export default Valeurs;
