import React from "react";
import bgWhite from "../assets/whiteBg.jpg";
import Bilan from "../assets/bilan.jpg";
import Conseil from "../assets/conseil.jpg";
import Suivi from "../assets/Suivi.jpg";

const cards = [
  {
    id: 1,
    title: "LE BILAN PATRIMONIAL",
    text: `La première étape de notre collaboration consiste en la réalisation d’un bilan patrimonial complet. Cette analyse détaillée de votre situation actuelle nous permet de comprendre vos besoins, vos objectifs et vos contraintes, afin de vous proposer des solutions adaptées.`,
    image: Bilan,
  },
  {
    id: 2,
    title: "CONSEILS ET STRATÉGIES PERSONNALISÉS",
    text: `Sur la base du bilan patrimonial, nous élaborons des stratégies sur mesure pour optimiser votre épargne, vos investissements et la structuration de votre patrimoine.`,
    image: Conseil,
  },
  {
    id: 3,
    title: "SUIVI ET ACCOMPAGNEMENT",
    text: `La gestion de patrimoine est un processus continu. Nous vous accompagnons à chaque étape, en ajustant nos conseils en fonction de l'évolution de votre situation personnelle et des conditions économiques. Vous bénéficiez d'un suivi régulier et de notre disponibilité pour répondre à vos questions et besoins.`,
    image: Suivi,
  },
];

function DomaineCompetences2() {
  return (
    <div
      className="custom-color min-h-[75vh] bg-gray-300 relative overflow-hidden"
      id="domaines"
    >
      <div
        className="absolute inset-0 bg-cover bg-no-repeat bg-center bg-fixed black-and-white mt-42 opacity-70 bg-image"
        style={{
          backgroundImage: `url(${bgWhite})`,
        }}
      ></div>

      <div className="relative z-10 h-auto">
        <div className="mx-4 md:mx-36 my-auto mb-24">
          <h2 className="text-5xl md:text-5xl font-seasons text-left md:text-left text-customGreen mb-44 mt-24 ">
            Nos services
          </h2>
          <div className="flex flex-col md:flex-row justify-between items-center md:items-start space-y-18 md:space-y-0 md:space-x-36 text-customGreen font-trebuc">
            {cards.map((card) => (
              <div
                key={card.id}
                className="bg-white rounded-xl shadow-2xl p-6 md:w-1/3 w-full text-center md:text-left relative mb-36 md:mb-0"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="h-48 w-48 absolute -top-24 left-1/2 transform -translate-x-1/2 rounded-full mb-24 md:mb-0 "
                />
                <div className="pt-24">
                  <h3 className="text-2xl md:text-2xl mb-4 text-customGreen font-seasons">
                    {card.title}
                  </h3>
                  <p className="text-gray-700 text-md md:text-lg leading-relaxed font-arial">
                    {card.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomaineCompetences2;
