// App.jsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import NavBar from "./components/NavBar";
import Home from "./components/Home";
import DomaineCompetences2 from "./components/DomainesCompetences2";
import Presentation from "./components/Presentation";
import Contact from "./components/Contact";
import Valeurs from "./components/Valeurs";
import Valeurs2 from "./components/Valeurs2";

import MentionsLegales from "./components/MentionsLegales";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div>
        <div className="z-90">
          <NavBar />
        </div>

        {/* Contenu principal */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <Presentation />
                <DomaineCompetences2 />
                <Valeurs />
                <Valeurs2 />

                <Contact />
                <Footer />
              </>
            }
          />

          {/* Ajoutez une nouvelle route pour les mentions légales */}
          <Route path="/mentionslegales" element={<MentionsLegales />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
