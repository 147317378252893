import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link as ScrollLink, scroller } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import LogoKevinWhite from "../assets/LOGOTEST.png";
import "./NavBar.css";

function NavBar() {
  const [nav, setNav] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(true);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1023);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const links = [
    { id: 1, link: "Qui sommes nous ?", target: "cabinet" },
    { id: 2, link: "Nos services", target: "domaines" },
    { id: 3, link: "Pourquoi nous choisir ?", target: "valeurs" },
    { id: 5, link: "Contactez nous", target: "contact" },
  ];

  const handleNavToggle = () => {
    setNav(!nav);
  };

  const handleSectionClick = (target) => {
    if (location.pathname === "/mentionslegales") {
      window.location.href = `/`;
    } else {
      handleNavToggle();
      scroller.scrollTo(target, {
        duration: 1000,
        smooth: true,
        offset: -70,
      });
    }
  };

  return (
    <div className="relative z-50">
      <div className="flex justify-between items-center w-full h-32 px-4 text-white bg-customGreen-20 fixed font-seasons backdrop-blur">
        <div>
          <ScrollLink
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={1000}
          >
            <img
              src={LogoKevinWhite}
              alt="Logo Kevin"
              className="md:h-auto md:w-44 h-32 w-32 py-4 px-4 ml-8 cursor-pointer"
            />
          </ScrollLink>
        </div>
        <ul
          className={`lg:flex mr-4 2xl:mr-24 ${
            isMenuVisible || (nav && !isLargeScreen)
              ? "flex flex-col justify-center items-center space-y-12 text-lg md:text-3xl font-seasons absolute top-0 left-0 w-full h-screen bg-customGreen p-8"
              : "hidden"
          }`}
        >
          {links.map(({ id, link, target }) => (
            <li
              key={id}
              className="px-4 lg:px-3 cursor-pointer font-seasons  text-2xl text-white hover-scale-105 duration-300 space-x-12"
              onClick={() => handleSectionClick(target)}
            >
              <span className="lg:hidden flex items-center">
                {id === 3 ? "Compétences" : link} {/* Raccourcir le texte */}
              </span>
              <span className="hidden lg:flex items-center">
                {id === 3 ? "Pourquoi nous choisir ?" : link}{" "}
              </span>
            </li>
          ))}
        </ul>
        <div
          onClick={handleNavToggle}
          className="cursor-pointer pr-4 z-10 text-gray-500 lg:hidden"
        >
          {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
