import React from "react";
import LogoKevinWhite from "../assets/LOGO4.jpg";
import iconeLK from "../assets/iconeLK.png";
import googleCalendar from "../assets/google-calendar.png";

import ContactPhoto from "../assets/Contact.jpg"; // Ajouter l'import de votre photo de contact

function Contact() {
  return (
    <div className="flex flex-col">
      <div
        name="contact"
        className="w-full min-h-screen flex flex-col lg:flex-row items-center justify-center bg-gray-100"
      >
        <div className="max-w-screen-lg lg:w-1/2 mx-auto p-0 lg:p-0 flex flex-col justify-center items-center">
          <img
            src={ContactPhoto}
            alt="Contact"
            className="lg:h-full lg:w-full w-full h-auto object-cover"
          />
        </div>
        <div
          className="max-w-screen-lg lg:w-1/2 mx-auto p-4 flex flex-col justify-center items-center"
          id="contact"
        >
          <div className="pb-8 ">
            <p className="md:text-4xl text-3xl font-seasons text-customGreen  p-2 mx-4 md:mx-20">
              Contactez nous
            </p>
            <p className="py-6 font-arial text-lg mx-6 md:mx-24">
              Nous serions ravis de vous rencontrer pour discuter de vos besoins
              en gestion de patrimoine. Que vous souhaitiez réaliser un bilan
              patrimonial, optimiser votre épargne ou structurer vos
              investissements, ELIA Conseil est là pour vous accompagner.{" "}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-customGreen text-black opacity-80 flex flex-col justify-center p-4 rounded-xl shadow-2xl mx-auto">
              <img
                src={LogoKevinWhite}
                alt="Logo Kevin"
                className="md:h-80 md:w-80 h-64 w-64 py-4 px-4 mx-auto cursor-pointer rounded-full"
              />

              <div className=" text-lg md:text-xl font-arial font-light mb-0 md:px-18 md:py-18 text-gray-100">
                <p className="mb-2">Entrepreneure individuelle</p>
                <p className="mb-2">
                  Tel : <strong className="font-bold">06 14 76 32 01</strong>
                </p>
                <p className="mb-2">
                  Courriel :{" "}
                  <strong className="font-bold">amelie@elia-conseil.fr</strong>
                </p>
                <p>Adresse : 90 impasse de la Bouvate, 26600 LARNAGE</p>
                <p className="font-arial text-bold text-2xl underline  text-center mt-24">
                  Prenons rendez-vous :
                </p>
                <a
                  href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ31RTiq2fQ89GGKm0f55N-apZNQ9Jb81Pokxo4uTriMf9OhxXbm1GVsS9DQ3kzYzEIHJd13m_wB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="w-12 h-12 cursor-pointer mx-auto mt-6 mb-4 md:mb-0"
                    src={googleCalendar}
                    alt="iconeCV"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
