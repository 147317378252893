import React from "react";
import AmelieConseil from "../assets/Milou.png";
import TextePresentation from "../assets/textePresentation.png";
import iconeCV from "../assets/iconeCV.png";

import "./NavBar.css";

function Presentation() {
  return (
    <div
      id="cabinet"
      className="flex flex-col items-center justify-center bg-cover"
    >
      <div className="flex flex-col md:flex-row  bg-customGreen">
        <div className="w-full md:w-1/3 h-4/5 md:h-full bg-customGreen my-auto">
          <img
            className="w-3/4 xl:w-2/3 h-full object-cover rounded-full mx-auto xl:mx-32 py-12 xl:ml-48"
            src={AmelieConseil}
            alt="Marine Avocate"
          />
          <img
            className="w-full xl:w-full h-full object-cover rounded-full mx-auto xl:mx-32 py-12 xl:ml-24"
            src={TextePresentation}
            alt="Marine Avocate"
          />
        </div>

        <div className=" bg-customGreen  flex flex-col justify-start md:ml-24 w-full md:w-2/3 md:h-2/3 2xl:mt-12 xl:mt-12 lg:mt-12 p-8 text-left ">
          <div className="    rounded-xl  p-6 xl:p-12 text-justify xl:mx-48   my-auto bg-customGreen opacity-100">
            <p className=" md:text-5xl text-3xl font-seasons  md:px-0 text-white">
              Qui sommes nous ?
            </p>
            <p className="mt-24  md:text-xl font-arial text-white">
              Amélie BRUNIERE, experte en gestion de patrimoine, a créé ELIA
              Conseil avec une vision claire : accompagner chaque client dans la
              réalisation de ses objectifs financiers, tout en offrant une
              approche humaine et personnalisée. Forte d'une expérience solide
              dans le domaine, elle met son expertise à votre service pour vous
              aider à naviguer dans le monde complexe de la gestion
              patrimoniale. Etes-vous prêts à prendre des décisions éclairées
              pour votre avenir ?
            </p>
            <p className="mt-4  md:text-xl font-arial text-white">
              Etes-vous prêts à prendre des décisions éclairées pour votre
              avenir ?
            </p>
            <a
              href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ31RTiq2fQ89GGKm0f55N-apZNQ9Jb81Pokxo4uTriMf9OhxXbm1GVsS9DQ3kzYzEIHJd13m_wB"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="text-black bg-white font-arial px-4 py-2 mx-auto md:mx-0 flex items-center rounded-sm shadow-2xl hover:scale-110 duration-300 md:text-lg mt-8 uppercase font-semibold">
                Réalisez votre bilan <br /> patrimonial
              </button>
            </a>

            {/*       <p className="mt-4 text-sm md:text-2xl  font-trebuc">
              Elle a réalisé un stage de 6 mois au sein de la chambre fonction
              publique d’Etat du Tribunal administratif de LYON lors de sa
              formation à l’Ecole des Avocats de la Région Rhône Alpes ainsi
              qu’un stage de 6 mois dans un cabinet de droit public lyonnais.
            </p>*/}
            {/*  <p className="mt-4 text-sm md:text-2xl  font-trebuc">
              Elle est chargé d’enseignement en droit public à l’Université
              d’Aix Marseille.
  </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
